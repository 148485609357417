@import "./tailwind-presets.css";
// @import "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap";
@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";
@import "react-color-palette/dist/css/rcp.css";
@import "./variables";
@import "./utilities";
@import "./animations";
@import "./inputs";
@import "./spinner";
@import "./fh-oscar";
@import "./date-picker";
@import "./color-picker";
// @import "./gilroy";

* {
  -webkit-overflow-scrolling: auto;
}

body {
  margin: 0;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

html {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4 {
  font-family: "FH Oscar", "Inter", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  letter-spacing: 0.5px;
}

canvas {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

//auth display images
.auth-display-image {
  figure {
    padding-top: 110%;
    position: relative;
    img {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.image-picker {
  position: relative;
  &::after,
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border: dashed 1px #d8d8d8;
    border-radius: 10px;
    top: 10%;
  }
  &::after {
    left: -50%;
    transform: rotate(-15deg);
  }
  &::before {
    left: 50%;
    transform: rotate(15deg);
  }
}

.app-table {
  tr:last-child {
    td:first-child {
      border-bottom-left-radius: 15px;
    }
    td:last-child {
      border-bottom-right-radius: 15px;
    }
  }
}

.store-hero-image {
  padding-top: 35%;
  @include sm {
    padding-top: 30%;
  }
  @include md {
    padding-top: 25%;
  }
  @include lg {
    padding-top: 20%;
  }
  background: linear-gradient(272.94deg, rgba(242, 240, 249, 0.6) -4.11%, rgba(250, 250, 252, 0.6) 100%);
}

.nav-toggle {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  padding: 5px;
  position: relative;
  transition: 0.3s ease-out all;
  &-inner {
    display: inline-block;
    height: 1.5px;
    width: 100%;
    background: #000;
    border-radius: 10px;
    position: relative;
    transition: 0.3s ease-out all;
    &::before,
    &::after {
      position: absolute;
      content: "";
      top: -6px;
      left: 0;
      height: 1.5px;
      width: 100%;
      background: #000;
      transition: 0.3s ease-out all;
    }
    &::after {
      bottom: -6px;
      top: unset;
    }
    &:hover {
      background: #332098 !important;
      &::before,
      &::after {
        background: #332098 !important;
      }
    }
  }
}

.order-item:not(:last-child) {
  position: relative;
  //   width: 100% !important;

  &::after {
    position: absolute;
    content: "";
    height: 1px;
    width: calc(100% - 40px);
    background: #f0f0f0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @include sm {
      width: calc(100% - 50px);
    }
  }
}

.side-nav-item {
  display: block;

  &::before {
    content: "";
    height: 100%;
    width: 4px;
    position: absolute;
    top: 0;
    left: -20px;
    border-radius: 0 20px 20px 0;
  }

  &--active {
    &::before {
      background: currentColor;
    }
  }
}

.dashed-line {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E5E5E5FF' strokeWidth='3' stroke-dasharray='6%2c 20' opacity='0.7' stroke-dashoffset='0' strokeLinecap='square'/%3e%3c/svg%3e");
}

.leaderboard-page {
  background: url("/images/patterns/light-orange-pattern.svg");
  background-size: cover;
}
// .thumbnail-image {
//   display: block;

//   &::after {
//     width: 100%;
//     height: 100%;
//     display: block;
//     content: "";
//     position: absolute;
//     border: 5px #39b588 solid;
//     top: 0;
//     left: -10px;
//     z-index: 5;
//     box-sizing: content-box;
//   }
// }

.overflow-x-auto {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Optional: Prevent scrollbar space from being reserved in IE, Edge, and Firefox */
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

@media (min-width: 800px) {
  .auth-pages-bg {
    background: url("/images/patterns/purple-pattern.svg");
    background-size: cover;
  }
}

.black-gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
}


.abs-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.outline-text {
  -webkit-text-stroke: 0.04em #5644B3;
  paint-order: stroke fill;
}

.elipsis-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.elipsis-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}